import { IEnvironment } from '@types';

export const environment: IEnvironment = {
  production: false,
  apiUrl: 'https://lolypto-api2.dev.scrij.com',
  wsUrl: 'wss://lolypto-api2.dev.scrij.com',
  cloudfrontUrl: 'https://d1nhucx0lj40wv.cloudfront.net',
  ga: '',
  telegramLoginBotName: 'lolyptotonbot',
};
